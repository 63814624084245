import "./App.scss";
import { useEffect, useState } from "react";
import logo from "./assets/logo_new.png";
import profile from "./assets/profile_new.png";
import offer1 from "./assets/offer1.png";
import offer2 from "./assets/offer2.png";
import offer3 from "./assets/offer3.png";
import bundle from "./assets/bundle.png";
import { motion } from "framer-motion";
import axios from "axios";
import {
  AppchargeCheckout,
  AppchargeCheckoutInit,
  getPricePoints,
} from "appcharge-checkout-reactjs-sdk";
import {
  Box,
  Button,
  Drawer,
  Input,
  Stack,
  Switch,
  Typography,
  FormControlLabel,
} from "@mui/material";
import JSONInput from "react-json-editor-ajrm";

const env = process.env.REACT_APP_ENV || "dev";
const publisherToken =
  process.env.REACT_APP_PUBLISHER_TOKEN ||
  "c2b59d063050beccd58991eb36be6d9d6953dd0704af68c3bc74cd5d12084eef";
const checkoutToken =
  process.env.REACT_APP_CHECKOUT_TOKEN ||
  "85c6be660eaf1871ceeadebb6d2219cc52d395b8c37f72fe33dc0685965ce534";
let lambdaUrl;
switch (env) {
  case "dev":
  default:
    lambdaUrl = "https://rryhv338ih.execute-api.us-east-1.amazonaws.com/dev";
    break;
  case "staging":
    lambdaUrl =
      "https://wh92go3k1e.execute-api.us-east-1.amazonaws.com/staging";
    break;
  case "sandbox":
    lambdaUrl =
      "https://a3no38e5c1.execute-api.us-east-1.amazonaws.com/sandbox";
    break;
  case "production":
    lambdaUrl = "https://myf6de8jsh.execute-api.us-east-1.amazonaws.com/prod";
}

const mockData = {
  customer: {
    id: "John Doe",
    email: "test@appcharge.com",
  },
  priceDetails: {
    price: 1550,
    currency: "usd",
  },
  offer: {
    name: "Crazy Pack",
    sku: "CrazyPack",
    assetUrl:
      "https://media-dev.appcharge.com/media/64ad4f25cc1a482bac467ae5/fire-fire-logo-fire-fire-icon-fire-sign-fire-symbol-transparent-background-ai-generative-free-png.png",
    description: "Crazy Pack Bundle",
  },
  items: [
    {
      name: "Coins",
      assetUrl: "https://media-dev.appcharge.com/media/product-3.png",
      sku: "coins_xoxoxo",
      quantity: 6580,
    },
    {
      name: "Tokens",
      assetUrl: "https://media-dev.appcharge.com/media/bills.png",
      sku: "coins_xoxoxo",
      quantity: 150,
    },
    {
      name: "Boosters",
      assetUrl: "https://media-dev.appcharge.com/media/booster.png",
      sku: "coins_xoxoxo",
      quantity: 3,
    },
  ],
  sessionMetadata: {},
};

function App() {
  const [isIframeOpen, setIsIframeOpen] = useState(false);
  const [session, setSession] = useState();
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [formattedPricePoint, setFormattedPricePoint] = useState(null);
  const [price, setPrice] = useState(
    `$ ${(mockData.priceDetails.price / 100).toFixed(2)}`
  );
  const [customJSON, setCustomJSON] = useState(mockData);
  const [isJSONdrawerOpen, setIsJSONdrawerOpen] = useState(false);
  const [isAdvancedOptionsOpen, setIsAdvancedOptionsOpen] = useState(false);
  const [locale, setLocale] = useState("");
  const [customCheckoutUrl, setCustomCheckoutUrl] = useState(
    `https://checkout-v2-${env}.appcharge.com`
  );
  const [customSessionToken, setCustomSessionToken] = useState("");
  const [customCheckoutToken, setCustomCheckoutToken] = useState(checkoutToken);
  const [isMobileSDKMode, setIsMobileSDKMode] = useState(false);

  const clickHandler = () => {
    setIsIframeLoading(true);
    setCustomCheckoutToken("");
    setCustomSessionToken("");
    axios
      .post(`${lambdaUrl}/createSession`, customJSON, {
        headers: {
          "x-publisher-token": publisherToken,
        },
      })
      .catch((err) => console.log(err))
      .then((res) => {
        setIsIframeOpen(true);
        setSession({
          token: res.data.checkoutSessionToken,
          url: res.data.url,
        });
      });
  };

  const getPricePointsHandler = async () => {
    const res = await getPricePoints(process.env.REACT_APP_ENV);
    const relevantPrice = res.pricingPoints.find(
      (p) => Number(p.basePriceInUSD) === customJSON.priceDetails.price
    );
    if (!relevantPrice) return;
    const newJSON = { ...customJSON };
    newJSON.priceDetails.price = relevantPrice.localizedPrice;
    newJSON.priceDetails.currency = res.pricingPointData.currencyCode;
    setCustomJSON(newJSON);
    setFormattedPricePoint(relevantPrice.formattedPrice);
  };

  const runCheckoutHandler = () => {
    setIsIframeOpen(true);
    setIsJSONdrawerOpen(false);
    setIsAdvancedOptionsOpen(false);
    setSession({
      token: customSessionToken,
      url: customCheckoutUrl,
    });
  };

  useEffect(() => {
    window.addEventListener("message", receiveMessage, false);

    function receiveMessage(event) {
      // Check the origin of the sender
      if (event.origin !== "https://checkout-v2-dev.appcharge.com") {
        return;
      }

      // Access the data sent from the iframe
      const dataFromIframe = event.data;
      switch (dataFromIframe) {
        case "close":
          setIsIframeOpen(false);
          break;
        default:
          break;
      }
      // console.log("Data received from iframe:", dataFromIframe);

      // Do something with the data
      // For example, update the UI or perform some action in your app
    }
  }, []);

  useEffect(() => {
    setPrice(`$ ${(customJSON.priceDetails.price / 100).toFixed(2)}`);
  }, [customJSON]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isAdvancedOptionsOpen}
        onClose={() => setIsAdvancedOptionsOpen(false)}
      >
        <Stack
          width={"400px"}
          height={"100%"}
          className="json-drawer"
          style={{
            backgroundColor: "#1e1e1e",
          }}
          p={2}
          gap={2}
        >
          <Box>
            <h2 style={{ color: "white" }}>Advanced Options</h2>
          </Box>
          <Typography color="white">Session Token</Typography>
          <Input
            type="text"
            placeholder="Session Token"
            value={customSessionToken}
            onChange={(e) => setCustomSessionToken(e.target.value)}
            sx={{
              background: "white",
              padding: "0.5em",
              borderRadius: "0.5em",
            }}
          />
          <Typography color="white">Checkout URL</Typography>
          <Input
            type="text"
            placeholder="Checkout URL"
            value={customCheckoutUrl}
            onChange={(e) => setCustomCheckoutUrl(e.target.value)}
            sx={{
              background: "white",
              padding: "0.5em",
              borderRadius: "0.5em",
            }}
          />
          <Typography color="white">Checkout Token</Typography>
          <Input
            type="text"
            placeholder="Checkout Token"
            value={customCheckoutToken}
            onChange={(e) => setCustomCheckoutToken(e.target.value)}
            sx={{
              background: "white",
              padding: "0.5em",
              borderRadius: "0.5em",
            }}
          />
          <FormControlLabel
            control={
              <Switch
                checked={isMobileSDKMode}
                onChange={(e) => setIsMobileSDKMode(e.target.checked)}
              />
            }
            label={<Typography color="white">mobile SDK mode</Typography>}
          />
          <Button variant="contained" onClick={runCheckoutHandler}>
            Run Checkout
          </Button>
        </Stack>
      </Drawer>
      <Drawer
        anchor={"right"}
        open={isJSONdrawerOpen && !isAdvancedOptionsOpen}
        onClose={() => setIsJSONdrawerOpen(false)}
      >
        <Stack
          width={"600px"}
          className="json-drawer"
          style={{
            backgroundColor: "#1e1e1e",
          }}
          py={2}
        >
          <Stack direction="row" justifyContent="flex-end" pr={2}>
            <Button
              onClick={() => setIsAdvancedOptionsOpen(true)}
              variant="outlined"
              sx={{
                maxWidth: 200,
              }}
            >
              Advanced Options
            </Button>
          </Stack>
          <Stack
            p={2}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              backgroundColor: "#1e1e1e",
            }}
          >
            <h2 style={{ color: "white" }}>Edit Offer JSON</h2>
            <Button
              onClick={() => setIsJSONdrawerOpen(false)}
              sx={{
                color: "white",
                fontSize: "1rem",
              }}
              variant="contained"
            >
              SAVE
            </Button>
          </Stack>
          <JSONInput
            onBlur={(e) => setCustomJSON(e.jsObject)}
            id="a_unique_id"
            placeholder={customJSON}
            theme="dark"
            locale={locale}
            width="100%"
            height="100%"
            style={{
              body: {
                fontSize: "16px",
              },
            }}
          />
        </Stack>
      </Drawer>
      <div className="wrapper">
        <AppchargeCheckoutInit
          checkoutToken={checkoutToken}
          environment={env === "production" ? "prod" : env}
        />
        <div
          className="loading-iframe"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.75)",
            zIndex: 999999999,
            display: isIframeLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            fontSize: "2rem",
          }}
        >
          <span className="loader"></span>
        </div>
        {isIframeOpen && (
          <motion.div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100vh",
            }}
            initial={{ zIndex: 9999999 }}
          >
            <AppchargeCheckout
              mode={isMobileSDKMode ? "mobile-sdk" : ""}
              onInitialLoad={() => setIsIframeLoading(false)}
              onOrderCreated={(params) => {
                console.log("onOrderCreated", { params });
                setIsIframeLoading(false);
              }}
              onClose={(params) => {
                console.log("onClose", { params });
                setIsIframeOpen(false);
              }}
              onOrderCompletedFailed={(params) =>
                console.log("onOrderCompletedFailed", { params })
              }
              onOrderCompletedSuccessfully={(params) =>
                console.log("onOrderCompletedSuccessfully", { params })
              }
              onPaymentIntentFailed={(params) =>
                console.log("onPaymentIntentFailed", { params })
              }
              onPaymentIntentSuccess={(params) =>
                console.log("onPaymentIntentSuccess", { params })
              }
              checkoutUrl={session?.url}
              checkoutToken={customCheckoutToken || checkoutToken}
              sessionToken={session?.token}
              locale={locale}
            />
          </motion.div>
        )}
        <header>
          <div>
            <img src={profile} alt="profile" />
          </div>
          <div>
            <img
              onClick={() => setIsJSONdrawerOpen(true)}
              src={logo}
              className="logo"
              alt="logo"
            />
          </div>
          <div>
            <Stack width={"150px"} gap={2}>
              <button onClick={getPricePointsHandler}>Get price points</button>
              <select
                value={locale}
                onChange={(e) => {
                  setLocale(e.target.value);
                }}
              >
                <option value="">Language</option>
                <option value="en">English</option>
                <option value="ja">Japanese</option>
                <option value="ko">Korean</option>
                <option value="de">German</option>
              </select>
            </Stack>
          </div>
        </header>
        <section className="offers">
          <img src={offer1} alt="offer1" />
          <img src={offer2} alt="offer2" />
          <img src={offer3} alt="offer3" />
        </section>
        <section className="bundles">
          <div>
            <img
              onClick={() => clickHandler()}
              src={bundle}
              alt="bundle"
              data-testid="offer-1"
            />
            <div className="price">{formattedPricePoint || price}</div>
          </div>
          <div>
            <img
              onClick={() => clickHandler()}
              src={bundle}
              alt="bundle"
              data-testid="offer-2"
            />
            <div className="price">{formattedPricePoint || price}</div>
          </div>
          <div>
            <img
              onClick={() => clickHandler()}
              src={bundle}
              alt="bundle"
              data-testid="offer-3"
            />
            <div className="price">{formattedPricePoint || price}</div>
          </div>
        </section>
      </div>
    </>
  );
}

export default App;
